<template>
    <LiefengContent>
        <template v-slot:title>{{ menuCodeObj.menuName || "操作手册" }}</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input :maxlength="20" v-model.trim="title" placeholder="请输入标题搜索" style="width: 200px; margin-right: 10px"></Input>
                </FormItem>
                <Button style="margin-right: 10px" type="primary" @click="searchBtn">查询</Button>
                <Button style="margin-right: 10px" type="info" @click="resetBtn">重置</Button>
                <span>
                    <Icon type="md-eye" style="margin-right:10px"/>
                    {{readNum || 0}}次
                </span>
            </Form>
        </template>
        <template v-slot:contentArea>
            <div class="content-div">
                <div class="menu-div">
                    <Menu ref="menus" theme="light" :active-name="menuName" @on-select="selectMenu">
                        <MenuItem :name="item.id" v-for="(item, index) in tableData" :key="index">
                            {{ item.title }}
                        </MenuItem>
                    </Menu>
                    <div class="more-div" @click="changeMore" v-if="!showMore">
                        <span v-if="!loading">点击加载更多</span>
                        <span v-if="loading">
                            <Icon type="ios-loading" class="ivu-anim-loop" size="24" />
                        </span>
                    </div>
                </div>
                <div class="content-view" v-if="detailMessage.content && detailMessage.conetnt != '' && showContent">
                    <div class="content-all">
                        <div class="title">{{detailMessage.title || ''}}</div>
                        <div class="content" v-html="detailMessage.content"></div>
                    </div>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
export default {
    components: { LiefengContent },
    data() {
        return {
            title: "",
            menuCodeObj: {},
            page: 1,
            pageSize: 10,
            tableData: [],
            total: 0,
            currentPage: 0,
            loading: false,
            menuName: "",
            showMore: false,
            detailMessage: {},
            showContent: false,
            oneItem: {},
            readNum:0
        }
    },
    async mounted() {},
    async created() {
        await this.getMenuList()
        await this.getList()
        if(this.tableData && this.tableData.length) await this.getDetail(this.menuName, this.oneItem.businessType, this.oneItem.functionType)
        this.$nextTick(() => {
            this.$refs.menus.updateActiveName()
        })
    },
    methods: {
        // 点击加载更多
        changeMore() {
            if (this.loading) return
            if (this.showMore) return
            this.page++
            this.getList()
        },
        // 点击菜单选项
        async selectMenu(val) {
            this.menuName = val
            this.tableData.map(async item => {
                if (item.id == val) {
                    this.readNum = item.behavior &&  item.behavior != '' ? item.behavior.readNum : 0
                    await this.getDetail(val, item.businessType, item.functionType)
                }
            })
        },
        // 查询
        searchBtn() {
            this.page = 1
            this.tableData = []
            this.getList()
        },
        // 重置
        resetBtn() {
            this.title = ""
            this.searchBtn()
        },
        // 获取菜单数据
        async getMenuList() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // 获取分页数据
        async getList() {
            this.$Message.loading({
                content: "正在加载数据",
            })
            this.loading = true
            await this.$get("/info/api/app/information/queryDataPageApi", {
                title: this.title,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                businessType: this.menuCodeObj.businessType,
                functionType: "",
                columnCode: this.menuCodeObj.columnCode,
                statusList: "4",
                terminal: 4, //访问终端；0:全部；1:tv；2:pc；3:app；4:小程序；5:公众号
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                page: this.page,
                pageSize: this.pageSize,
                orgCode: parent.vue.loginInfo.userinfo.communityCode || '',
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.code == 200) {
                    this.tableData = this.$core.unique(this.tableData.concat(res.dataList), "id")
                    if (this.tableData.length) {
                        this.menuName = this.tableData[0].id
                        this.oneItem = this.tableData[0]
                        this.readNum = this.tableData[0] && this.tableData[0].behavior &&  this.tableData[0].behavior != '' ? this.tableData[0].behavior.readNum : 0
                    }
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                    this.showMore = this.tableData.length < this.total ? false : true
                } else {
                    this.$Message.error({
                        content: "获取数据列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取信息详情
        async getDetail(infoId, businessType, functionType) {
            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId,
                businessType,
                functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                terminal: 2,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    this.detailMessage = res.data
                    this.showContent = false
                    this.$nextTick(() => {
                        this.showContent = true
                    })
                } else {
                    this.$Message.error({
                        content: "获取用户详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
<style>
.content-all img,video {
    max-width: 100%;
}
</style>
<style lang="less" scoped>
.content-div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    .menu-div {
        height: 100%;
        overflow-y: scroll;
        .more-div {
            border-top: 1px solid #dcdee2;
            widows: 100%;
            text-align: center;
            padding: 20px 0;
        }
    }
    .content-view {
        flex: 1;
        height: 100%;
        max-width: 80%;
        .content-all {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            .title{
                padding: 10px;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
}
</style>